import React from 'react';
import { getSrc } from 'gatsby-plugin-image'
import { graphql, PageProps } from 'gatsby';
import styled from '@emotion/styled';
import { PortableText } from '@portabletext/react'
import SEO from '../components/seo';
import Layout from '../components/layout';
import SanityHeroComponentBlock from '../components/blocks/SanityHeroComponent';
import TableOfContents, { rawDataNode } from '../components/tableOfContents';
import FormattedPortableText from '../components/formattedPortableText';

const StyledLayout = styled.span`
  .content-area {
    padding: 2rem;
  }
  .sticky {
    position: sticky;
    top: 0;
  }
  .left-nav-item {
    background-color: rgba(118, 74, 188, 0.04);
    padding: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .title {
    background-color: rgba(118, 74, 188, 0.05);
    width: 100%;
    height: 53px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    font-weight: 900;
    font-size: 13px;
    line-height: 13px;
    color: rgba(118, 74, 188, 1);
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
  .title > p {
    margin-bottom: 0;
  }
  .left-nav-item > .item-set {
    margin-bottom: 0;
    padding: 1rem 0rem;
    margin: 0rem 1rem;
    border-top: solid rgba(118, 74, 188, .1);
    display: flex;
    flex-direction: column;
  }
  .left-nav-item .item-set:nth-child(2) {
    border-top: none;
  }
  .left-nav-item .main-entry {
    font-size: 16px;
    font-weight: 900;
    color: rgba(118, 74, 188, 1);
  }
  .left-nav-item .sub-entry {
    font-size: 13px;
    font-weight: 500;
    color: rgba(118, 74, 188, 0.6);
  }
  .additional-info {
    margin-top: 1rem;
  }

  .display-sm-up {
    display: none;
  }
  .display-sm-down {
    display: flex;
  }
  @media (min-width: 576px) {
    .display-sm-up {
      display: flex;
    }
    .display-sm-down {
      display: none;
    }
  }
`

type AdditionalInfoProps = {
  content: rawDataNode[]
}

const AdditionalInfo = ({ content }: AdditionalInfoProps) => (
  <div className="left-nav-item">
    <div className="title align-items-center">
      <p>Additional Resources</p>
    </div>
    <span className="item-set">
      <PortableText value={content}/>
    </span>
  </div>
);

export default function PageTemplate({data}:PageProps<Queries.SeoPageLayoutQuery>) {
  const { seo, hero, rawContent, rawSidebarContent } = data.sanitySeoPage;
  const { title: seoTitle, rawDescription: seoDescription, openGraphImage } = seo || {}

  return (
    <Layout>
      <SEO
        image={openGraphImage && getSrc(openGraphImage.asset.gatsbyImageData)}
        title={seoTitle}
        description={seoDescription} />
      <StyledLayout>
        <div className='SanityHeroComponent'>
          <SanityHeroComponentBlock {...hero} />
        </div>
        <div className="container content-area">
          <div className="row">
            <span className="col-sm-3">
              <span className="sticky">
                <TableOfContents
                  rawContent={rawContent}
                  className='left-nav-item'
                  headerClassName='title align-items-center'
                  itemClasses={{
                    setClassName: 'item-set',
                    entryClassName: 'main-entry',
                    subEntryClassName: 'sub-entry',
                 }}
                />
                <span className='display-sm-up additional-info'>
                  <AdditionalInfo content={rawSidebarContent as unknown as AdditionalInfoProps['content']}/>
                </span>
              </span>
            </span>
            <span className="col-sm-9">
              <FormattedPortableText rawContent={rawContent} />
            </span>
          </div>
          <span className='display-sm-down additional-info'>
            <AdditionalInfo content={rawSidebarContent as unknown as AdditionalInfoProps['content']}/>
          </span>
        </div>
      </StyledLayout>
    </Layout>
  );
}

export const query = graphql`
query SeoPageLayout($id: String) {
  sanitySeoPage(id: {eq: $id}) {
    slug {
      current
    }
    hero {
      ...SanityHeroComponent
    }
    rawContent: _rawContent
    rawSidebarContent: _rawSidebarContent
    seo {
      keywords
      title
      rawDescription:  _rawDescription
      openGraphImage {
        asset {
          gatsbyImageData
        }
      }
    }
  }
}
`
